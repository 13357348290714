.App {
  text-align: center;  
  z-index: 2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  z-index: 2;
}

.title {
  font-size: 18px;
  color: #121D31;
  font-weight: bold;
  z-index: 2;
}

.logo {
  width: 50px;
  height: 50px;
}

.row {
  display: flex;
  flex-direction: row;
}

.dragger-container {
  border-radius: 4px;
  padding: 1rem;
  background-color: #ffffff;
  opacity: .9;
  border: dashed 2px #121D31;
}

.App-header {
  background-image: url('./assets/images/bg_w_photo.png');
	background-repeat: repeat;
	background-size: cover;
  min-height: 100vh;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #121D31;
  position: relative;
  z-index: 2;
}

.App-link {
  color: #37afd9;
  font-weight: bold;
  font-size: medium;
  z-index: 2;
}

.App-check {
  margin-top: .5rem;
  color: green;
  font-size: 24px;
  z-index: 2;
}

.App-error {
  color: #e94949;
  font-size: 24px;
}

.App-hash {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;  
  z-index: 2;
}

.bg-container {
  border-radius: 4px;
	background-color: #ffffff;
	opacity: 0.75;
  padding: 0 1rem;
	width: 80vh;
	height: 25%;
	position: absolute;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
